<template>
    <div style="background:#e7e7e7; min-height: 100vh;">
        <nav class="navbar d-print-none navbar-light d-flex justify-content-center mb-4"
            style="background-color: #003A8C;">
            <div class="container">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <div>
                            <b-dropdown id="dropdown-offset" offset="25" text="Opciones" class="m-2">
                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'"
                                    href="/registrarRec">
                                    Registrar Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'" href="/cargarCam">
                                    Cargar Camión Transportes
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <!--<b-dropdown-item v-if="userTipo === 'Adquisiciones' || userTipo === 'TI'"
                                    href="/registrarInterno">
                                    Reg. Recepción Interna
                                </b-dropdown-item>-->

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarInfo">
                                    Informe Traslados
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'Santiago' || userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarRecep">
                                    Buscar Guía Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/recepcionCC">
                                    Recepción Comercial
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>

                <div class="d-flex justify-content-center" id="logo">
                    <img class="img-fluid" src="https://seguimiento.cmimbral.cl/assets/img/logoSeguimiento.png"
                        width="300">
                </div>

                <div class="d-flex">
                    <span class="navbar-text text-white me-3">Usuario: {{ user?.nombre || 'Invitado' }}</span>
                    <button class="btn btn-danger ml-auto" @click="logout">
                        <b-icon icon="power" aria-hidden="true"></b-icon> Cerrar sesión
                    </button>
                </div>
            </div>
        </nav>
        <div class="container mt-3">
            <div class="card">
                <div class="card-header bg-primary text-white">
                    <h2 class="text-center"><strong>Buscar Recepción de Mercadería</strong></h2>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="filtroTipo" class="form-label">Filtrar por:</label>
                        <select id="filtroTipo" v-model="filtroTipo" class="form-select form-select-sm"
                            @change="limpiarFiltros">
                            <option value="">Seleccionar filtro</option>
                            <option value="fecha">Rango de fechas</option>
                            <option value="rut-fecha">Rango de fechas con rut proveedor</option>
                        </select>
                    </div>
                    <div v-if="filtroTipo === 'rut-fecha'" class="mb-3">
                        <label for="filtroRut" class="form-label">Rut Proveedor</label>
                        <input type="text" id="filtroRut" v-model="filtroRut" class="form-control form-control-sm"
                            @input="formatRut">
                    </div>
                    <div v-if="filtroTipo === 'fecha' || filtroTipo === 'rut-fecha'" class="mb-3">
                        <div class="row">
                            <div class="col-sm-2 pe-2">
                                <label for="fechaInicio" class="form-label">Fecha desde</label>
                                <input type="date" id="fechaInicio" v-model="fechaInicio"
                                    class="form-control form-control-sm">
                            </div>
                            <div class="col-sm-2 ps-2">
                                <label for="fechaFin" class="form-label">Fecha Hasta</label>
                                <input type="date" id="fechaFin" v-model="fechaFin"
                                    class="form-control form-control-sm">
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-primary text-white">
                                <tr>
                                    <th scope="col">N° Guía</th>
                                    <th scope="col">Proveedor</th>
                                    <th scope="col">Rut</th>
                                    <th scope="col">Orden</th>
                                    <th scope="col">Bultos</th>
                                    <th scope="col">Pallet</th>
                                    <th scope="col">Observación</th>
                                    <th scope="col">Foto</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dato, index) in datosFiltrados" :key="index">
                                    <td>{{ dato.folio_prov }}</td>
                                    <td>{{ dato.proveedor }}</td>
                                    <td>{{ dato.rut }}</td>
                                    <td>{{ dato.orden }}</td>
                                    <td>{{ dato.bultos }}</td>
                                    <td>{{ dato.pallet }}</td>
                                    <td>
                                        <div v-if="dato.observacion">
                                            {{ dato.observacion }}
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="dato.foto_path">
                                            <button @click="viewPhoto(dato.foto_path)"
                                                class="btn btn-secondary btn-uniform">
                                                <i class="fas fa-eye"></i> Ver
                                            </button>
                                        </div>
                                        <div v-else>
                                            <button @click="openModal(dato)" class="btn btn-success btn-uniform">
                                                <i class="fas fa-upload"></i> Subir Foto
                                            </button>
                                        </div>
                                    </td>
                                    <td>{{ dato.fecha_adquisicion }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para ver la foto en tamaño completo -->
        <div v-if="photoUrl" class="modal" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Foto</h5>
                        <button type="button" class="close" @click="closeModal">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <img :src="photoUrl" alt="Foto" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para subir la foto -->
        <div v-if="selectedDato" class="modal" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Subir Foto</h5>
                        <button type="button" class="close" @click="closeUploadModal">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input type="file" ref="fileInput" @change="handleFileUpload($event, selectedDato)"
                            accept="image/*" class="form-control-file">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { mapState } from "vuex";
import moment from 'moment';

// Función para parsear la fecha personalizada
function parsearFechaPersonalizada(fechaStr) {
    const partes = fechaStr.split(' ');
    const fechaPartes = partes[0].split('-');

    // Formato: día-mes-año
    const dia = parseInt(fechaPartes[0], 10);
    const mes = parseInt(fechaPartes[1], 10) - 1; // El mes en JavaScript empieza en 0
    const anio = parseInt(fechaPartes[2], 10);

    return new Date(anio, mes, dia);  // Se ignoran horas y minutos
}

export default {
    data() {
        return {
            user: null,
            datos: [],
            filtroTipo: '',
            filtroRut: '',
            fechaInicio: '',
            fechaFin: '',
            photoUrl: '',
            selectedDato: null, // Para la foto seleccionada en la modal
        };
    },

    computed: {
        formattedDate() {
            return moment(this.fecha_adquisicion).format('DD-MM-YYYY HH:mm');
        },
        userTipo() {
            return this.$store.getters.tipo;
        },
        datosFiltrados() {
            let datosFiltrados = this.datos;

            const fechaInicio = this.fechaInicio ? new Date(new Date(this.fechaInicio).setHours(0, 0, 0, 0)) : null;
            const fechaFin = this.fechaFin ? new Date(new Date(this.fechaFin).setHours(0, 0, 0, 0)) : null;

            console.log('Fecha inicio:', fechaInicio);
            console.log('Fecha fin:', fechaFin);

            if (this.filtroTipo === 'rut-fecha' && this.filtroRut && fechaInicio && fechaFin) {
                datosFiltrados = datosFiltrados.filter(dato => {
                    const fechaDatoSinHora = parsearFechaPersonalizada(dato.fecha);
                    if (isNaN(fechaDatoSinHora)) {
                        console.error('Fecha inválida en dato:', dato.fecha);
                        return false;
                    }
                    console.log('Comparando:', fechaDatoSinHora, fechaInicio, fechaFin);
                    return dato.rut.includes(this.filtroRut) &&
                        fechaDatoSinHora >= fechaInicio &&
                        fechaDatoSinHora <= fechaFin;
                });
            } else if (this.filtroTipo === 'fecha' && fechaInicio && fechaFin) {
                datosFiltrados = datosFiltrados.filter(dato => {
                    const fechaDatoSinHora = parsearFechaPersonalizada(dato.fecha);
                    if (isNaN(fechaDatoSinHora)) {
                        console.error('Fecha inválida en dato:', dato.fecha);
                        return false;
                    }
                    console.log('Comparando:', fechaDatoSinHora, fechaInicio, fechaFin);
                    return fechaDatoSinHora >= fechaInicio &&
                        fechaDatoSinHora <= fechaFin;
                });
            }

            return datosFiltrados.sort((a, b) => b.folio_prov - a.folio_prov);
        }
    },

    methods: {
        async agregarObservacion(dato) {
            const { value: observacion } = await Swal.fire({
                title: 'Agregar Observación',
                input: 'textarea',
                inputLabel: 'Observación',
                inputPlaceholder: 'Ingrese la observación',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return '¡Debe ingresar una observación!';
                    }
                }
            });
            if (observacion) {
                try {
                    await axios.put(`https://apisantiago.cmimbral.cl/recepcionadquisiciones/${dato.id}`, {
                        observacion
                    });
                    dato.observacion = observacion;
                    Swal.fire('Observación agregada', '', 'success');
                } catch (error) {
                    console.error("Error al agregar observación", error.message);
                    Swal.fire('Error', 'No se pudo agregar la observación', 'error');
                }
            }
        },

        openModal(dato) {
            this.selectedDato = dato;
            // Asegúrate de que el DOM haya sido actualizado antes de intentar hacer clic en el elemento de archivo
            this.$nextTick(() => {
                if (this.$refs.fileInput) {
                    this.$refs.fileInput.click();
                } else {
                    console.error("El ref 'fileInput' no está definido.");
                }
            });
        },

        handleFileUpload(event, dato) {
            const file = event.target.files[0];
            if (file) {
                dato.file = file;
                this.uploadFoto(dato);
            }
        },

        async uploadFoto(dato) {
            if (!dato.file) {
                Swal.fire('Error', 'Debe seleccionar una foto primero', 'error');
                return;
            }

            const formData = new FormData();
            formData.append('foto', dato.file);

            this.isUploading = true;

            try {
                const response = await axios.post(
                    `https://apisantiago.cmimbral.cl/recepcionadquisiciones/${dato.id}/foto`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                );

                Swal.fire('Foto subida', '', 'success');

                dato.foto_path = response.data.imageUrl;  // Asigna la nueva URL al campo foto_path

                dato.file = null; // Limpiamos el archivo seleccionado

                this.closeUploadModal();
                this.obtenerDatos();
            } catch (error) {
                Swal.fire('Error', 'No se pudo subir la foto', 'error');
            } finally {
                this.isUploading = false;
            }
        },

        viewPhoto(foto_path) {
            this.photoUrl = `https://apisantiago.cmimbral.cl${foto_path}`;
        },

        closeModal() {
            this.photoUrl = '';
        },

        closeUploadModal() {
            this.selectedDato = null;
        },

        limpiarFiltros() {
            this.filtroRut = '';
            this.fechaInicio = '';
            this.fechaFin = '';
        },

        async obtenerDatos() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/buscarAdquisiciones');
                this.datos = response.data;
            } catch (error) {
                console.error("Error al obtener datos", error.message);
            }
        },

        async obtenerUsuario() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/usuario', {
                    params: {
                        nombre: this.$store.getters.user,
                        correo: this.$store.getters.correo,
                        tipo: this.$store.getters.tipo,
                        rut: this.$store.getters.rut,
                        id: this.$store.getters.id,
                    }
                });
                this.user = response.data;
                // Si deseas actualizar el store con los datos recibidos:
                this.$store.dispatch('login', response.data);
            } catch (error) {
                console.error("Error al obtener usuario", error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al intentar obtener los datos del usuario.",
                });
            }
        },

        formatRut() {
            let rutLimpio = this.filtroRut.replace(/[^0-9Kk]/g, '');
            if (rutLimpio.length > 1) {
                if (rutLimpio.slice(-1).toUpperCase() === 'K') {
                    rutLimpio = rutLimpio.slice(0, -1) + 'K';
                }
                const cuerpo = rutLimpio.slice(0, -1);
                const digitoVerificador = rutLimpio.slice(-1);
                this.filtroRut = `${cuerpo}-${digitoVerificador}`;
            } else {
                this.filtroRut = rutLimpio;
            }
        },

        async logout() {
            try {
                await axios.post('https://apisantiago.cmimbral.cl/logout', {}, {
                });
                this.$router.push('/');
            } catch (error) {
                console.error("Error al cerrar sesión", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al cerrar sesión",
                    text: "Ocurrió un error al intentar cerrar sesión.",
                });
            }
        },

        // Método para mostrar la imagen en el modal
        showImage(fotoUrl) {
            this.imageUrl = fotoUrl;
            $('#imageModal').modal('show');
        }
    },

    created() {
        this.obtenerDatos();
        this.obtenerUsuario();
    }
}
</script>

<style scoped>
.btn-uniform {
    width: 110px;
    /* Ajusta según sea necesario */
    height: 45px;
    /* Ajusta la altura */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    white-space: nowrap;
    /* Para que el texto no se divida en múltiples líneas */
}

.btn-uniform i {
    margin-right: 5px;
    /* Espacio entre el icono y el texto */
}

.table td button {
    margin: 0 auto;
    /* Centrar botones dentro de las celdas de la tabla */
}

.custom-navbar-color {
    background-color: #013b8c;
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
}

.form-control-file {
    margin-top: 5px;
}

.fas.fa-eye {
    margin-right: 5px;
}

.fas.fa-upload {
    margin-right: 5px;
}

.modal-lg {
    max-width: 90%;
}

.modal-body img {
    width: 100%;
    height: auto;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.8);
}
</style>
