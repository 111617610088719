<template>
    <div style="background:#e7e7e7; min-height: 100vh;">
        <nav class="navbar d-print-none navbar-light d-flex justify-content-center mb-4"
            style="background-color: #003A8C;">
            <div class="container">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <div>
                            <b-dropdown id="dropdown-offset" offset="25" text="Opciones" class="m-2">
                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'"
                                    href="/registrarRec">
                                    Registrar Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item v-if="userTipo === 'Santiago' || userTipo === 'TI'" href="/cargarCam">
                                    Cargar Camión Transportes
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <!--<b-dropdown-item v-if="userTipo === 'Adquisiciones' || userTipo === 'TI'"
                                    href="/registrarInterno">
                                    Reg. Recepción Interna
                                </b-dropdown-item>-->

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarInfo">
                                    Informe Traslados
                                </b-dropdown-item>

                                <!-- Visible solo para Santiago y TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'Santiago' || userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/buscarRecep">
                                    Buscar Guía Recepción
                                </b-dropdown-item>

                                <!-- Visible solo para TI -->
                                <b-dropdown-item
                                    v-if="userTipo === 'TI' || userTipo === 'Recepción' || userTipo === 'Adquisiciones'"
                                    href="/recepcionCC">
                                    Recepción Comercial
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>

                <div class="d-flex justify-content-center" id="logo">
                    <img class="img-fluid" src="https://seguimiento.cmimbral.cl/assets/img/logoSeguimiento.png"
                        width="300">
                </div>

                <div class="d-flex">
                    <span class="navbar-text text-white me-3">Usuario: {{ user?.nombre || 'Invitado' }}</span>
                    <button class="btn btn-danger ml-auto" @click="logout">
                        <b-icon icon="power" aria-hidden="true"></b-icon> Cerrar sesión
                    </button>
                </div>
            </div>
        </nav>
        <div class="container mt-3">
            <div class="card">
                <div class="card-header bg-primary text-white">
                    <h2 class="text-center"><strong>Datos traslados de mercadería</strong></h2>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="filtroTipo" class="form-label">Filtrar por:</label>
                        <select id="filtroTipo" v-model="filtroTipo" class="form-select form-select-sm"
                            @change="limpiarFiltros">
                            <option value="">Seleccionar filtro</option>
                            <option value="fecha">Rango de fechas</option>
                        </select>
                    </div>
                    <div v-if="filtroTipo === 'fecha' || filtroTipo === 'traslado-fecha'" class="mb-3">
                        <div class="row">
                            <div class="col-sm-2 pe-2">
                                <label for="fechaInicio" class="form-label">Fecha desde</label>
                                <input type="date" id="fechaInicio" v-model="fechaInicio"
                                    class="form-control form-control-sm">
                            </div>
                            <div class="col-sm-2 ps-2">
                                <label for="fechaFin" class="form-label">Fecha Hasta</label>
                                <input type="date" id="fechaFin" v-model="fechaFin"
                                    class="form-control form-control-sm">
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-primary text-white">
                                <tr>
                                    <th scope="col">Traslado</th>
                                    <th scope="col">Folio Prov</th>
                                    <th scope="col">Proveedor</th>
                                    <th scope="col">Rut</th>
                                    <th scope="col">Orden</th>
                                    <th scope="col">Bultos</th>
                                    <th scope="col">Pallet</th>
                                    <th scope="col">Patente</th>
                                    <th scope="col">Chofer</th>
                                    <th scope="col">Observación</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Imprimir Informe</th>
                                    <th scope="col">Recepción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dato, index) in datosFiltrados" :key="index">
                                    <td>{{ dato.traslado }}</td>
                                    <td>{{ dato.folio_prov }}</td>
                                    <td>{{ dato.proveedor }}</td>
                                    <td>{{ dato.rut }}</td>
                                    <td>{{ dato.orden }}</td>
                                    <td>{{ dato.bultos }}</td>
                                    <td>{{ dato.pallet }}</td>
                                    <td>{{ dato.patente }}</td>
                                    <td>{{ dato.chofer }}</td>
                                    <td>{{ dato.observacion }}</td>
                                    <td>{{ dato.fecha_traslado }}</td>
                                    <td>
                                        <button class="btn btn-primary btn-sm"
                                            @click="imprimirInforme(dato.traslado)">Imprimir</button>
                                    </td>
                                    <td>{{ dato.recepcion }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Invisible div for printing -->
        <div id="printable" style="display: none;">
            <div class="print-content" v-if="trasladoToPrint">
                <div class="row">
                    <div class="col-3">
                        <img src="@/components/assets/Logo.png" width="200" alt="Marca Mimbral Azul">
                    </div>
                    <div class="col-6 text-center">
                        <h1><strong>Traslado de mercadería (copia)</strong></h1>
                    </div>
                    <div class="col-3 text-center">
                        <h5><strong>Folio: </strong>{{ trasladoToPrint }}</h5>
                    </div>
                </div>

                <!-- Add a break here for more spacing -->
                <div style="margin-top: 20px;"></div>

                <div class="row">
                    <div class="col-4">
                        <p class="text-center"><strong>Fecha: </strong>{{ datosFiltrados[0].fecha_traslado }}</p>
                    </div>
                    <div class="col-4">
                        <p class="text-center"><strong>Despachado por: </strong>{{ datosFiltrados[0].rut }}</p>
                    </div>
                    <div class="col-4">
                        <p class="text-center"><strong>Sucursal: </strong>Lo Ovalle</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 offset-2">
                        <p class="text-center"><strong>Chofer: </strong>{{ datosFiltrados[0].chofer }}</p>
                    </div>
                    <div class="col-4">
                        <p class="text-center"><strong>Patente: </strong>{{ datosFiltrados[0].patente }}</p>
                    </div>
                </div>

                <!-- Add another break for more spacing -->
                <div style="margin-top: 20px;"></div>

                <div class="table-responsive mt-3">
                    <table class="table table-bordered table-striped">
                        <thead class="bg-primary text-white">
                            <tr>
                                <th>N° folio</th>
                                <th>Proveedor</th>
                                <th>Rut</th>
                                <th>Orden</th>
                                <th>Bultos</th>
                                <th>Pallet</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(dato, index) in datosFiltrados" :key="index"
                                v-if="dato.traslado === trasladoToPrint">
                                <td>{{ dato.folio_prov }}</td>
                                <td>{{ dato.proveedor }}</td>
                                <td>{{ dato.rut }}</td>
                                <td>{{ dato.orden }}</td>
                                <td>{{ dato.bultos }}</td>
                                <td>{{ dato.pallet }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="margin-top: 20px;"></div>
                </div>
                <div class="observacion-title mt-3">
                    <strong>Observación:</strong>
                </div>
                <div class="observacion-container p-3"
                    style="border: 1px solid #ccc; display: flex; flex-direction: column; align-items: flex-start;">
                    <p>{{ datosFiltrados[0].observacion }}</p>
                </div>

                <div style="margin-top: 30px;"></div>
                <div class="d-flex justify-content-around mt-4">
                    <div>
                        <p>____________________________________</p>
                        <p>26329446-7 / Despachador</p>
                    </div>
                    <div>
                        <p>____________________________________</p>
                        <p>{{ datosFiltrados[0].chofer }} / Chofer</p>
                    </div>
                    <div>
                        <p>____________________________________</p>
                        <p>Recepcionado</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { mapState } from "vuex";
import { mapGetters } from 'vuex';
import moment from 'moment';

// Función para parsear la fecha personalizada
function parsearFechaPersonalizada(fechaStr) {
    const partes = fechaStr.split(' ');
    const fechaPartes = partes[0].split('-');

    // Formato: día-mes-año
    const dia = parseInt(fechaPartes[0], 10);
    const mes = parseInt(fechaPartes[1], 10) - 1; // El mes en JavaScript empieza en 0
    const anio = parseInt(fechaPartes[2], 10);

    return new Date(anio, mes, dia);  // Se ignoran horas y minutos
}

export default {
    /*computed: {
        formattedDate() {
            return moment(this.fecha).format('DD-MM-YYYY HH:mm');
        },
        userTipo() {
            return this.$store.getters.tipo;
        },
        ...mapState({
            user: state => state.user
        })
    },*/
    data() {
        return {
            user: null,
            tipo: '',
            datos: [],
            filtroTipo: '',
            filtroRut: '',
            fechaInicio: '',
            fechaFin: '',
            trasladoToPrint: null,
            trasladoDatos: null
        }
    },

    computed: {
        userTipo() {
            return this.$store.getters.tipo;
        },
        datosFiltrados() {
            let datosFiltrados = this.datos;

            const fechaInicio = this.fechaInicio ? new Date(new Date(this.fechaInicio).setHours(0, 0, 0, 0)) : null;
            const fechaFin = this.fechaFin ? new Date(new Date(this.fechaFin).setHours(0, 0, 0, 0)) : null;

            if (this.filtroTipo === 'fecha' && fechaInicio && fechaFin) {
                datosFiltrados = datosFiltrados.filter(dato => {
                    const fechaDatoSinHora = parsearFechaPersonalizada(dato.fecha);
                    if (isNaN(fechaDatoSinHora)) {
                        console.error('Fecha inválida en dato:', dato.fecha);
                        return false;
                    }
                    console.log('Comparando:', fechaDatoSinHora, fechaInicio, fechaFin);
                    return fechaDatoSinHora >= fechaInicio &&
                        fechaDatoSinHora <= fechaFin;
                });
            }

            console.log('Datos filtrados:', datosFiltrados);
            return datosFiltrados.sort((a, b) => b.traslado - a.traslado);
        }
    },

    methods: {

        convertirFecha(fechaStr) {
            const [fecha, hora] = fechaStr.split(' ');
            const [dia, mes, año] = fecha.split('-');
            const [horas, minutos] = hora.split(':');

            return new Date(`${año}-${mes}-${dia}T${horas}:${minutos}:00`);
        },


        async logout() {
            try {
                await axios.post('https://apisantiago.cmimbral.cl/logout', {}, {
                });
                this.$router.push('/');
            } catch (error) {
                console.error("Error al cerrar sesión", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al cerrar sesión",
                    text: "Ocurrió un error al intentar cerrar sesión.",
                });
            }
        },

        limpiarFiltros() {
            this.filtroRut = '';
            this.fechaInicio = '';
            this.fechaFin = '';
        },

        async fetchData() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/datosInformes', {
                });
                this.datos = response.data;

            } catch (error) {
                console.error("Error al obtener los datos de adquisiciones:", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al obtener los datos",
                    text: "Ocurrió un error al intentar obtener los datos.",
                });
            }
        },

        imprimirInforme(traslado) {
            this.trasladoToPrint = traslado;
            this.trasladoDatos = this.datos.filter(dato => dato.traslado === traslado);
            this.$nextTick(() => {
                const printableElement = document.getElementById('printable');
                printableElement.style.display = 'block'; // Asegura que el contenido a imprimir esté visible
                window.print();
                printableElement.style.display = 'none'; // Vuelve a ocultar el contenido después de imprimir
            });
        },

        async obtenerUsuario() {
            try {
                const response = await axios.get('https://apisantiago.cmimbral.cl/usuario', {
                    params: {
                        nombre: this.$store.getters.user,
                        correo: this.$store.getters.correo,
                        tipo: this.$store.getters.tipo,
                        rut: this.$store.getters.rut,
                        id: this.$store.getters.id,
                    }
                });
                this.user = response.data;
                // Si deseas actualizar el store con los datos recibidos:
                this.$store.dispatch('login', response.data);
            } catch (error) {
                console.error("Error al obtener usuario", error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al intentar obtener los datos del usuario.",
                });
            }
        },
    },

    mounted() {
        this.fetchData();
        this.obtenerUsuario();
        console.log('userTipo:', this.userTipo);
    }
}
</script>

<style>
.navbar {
    background: #013b8c;
}

.navbar-nav .nav-item .nav-link {
    color: white;
}

.navbar-nav .nav-item .nav-link:hover {
    color: #ffc107;
}

.navbar-text {
    color: white;
}

.btn-primary {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 16px;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.navbar-nav .nav-item {
    margin-right: 10px;
}

.cliente-column {
    width: 30%;
}

.form-select-sm,
.form-control-sm {
    max-width: 200px;
}

.table-responsive {
    margin-top: 20px;
}

@media print {
    body {
        margin: 0;
        padding: 0;
    }

    body * {
        visibility: hidden;
        /* Oculta todo el contenido */
    }

    #printable,
    #printable * {
        visibility: visible;
        /* Solo hace visible el contenido que necesitas imprimir */
    }

    #printable {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        /* Asegura que el contenido ocupe toda la página */
    }
}
</style>
