import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({

    state: {
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        correo: localStorage.getItem('correo') ? JSON.parse(localStorage.getItem('correo')) : null,
        rut: localStorage.getItem('rut') ? JSON.parse(localStorage.getItem('rut')) : null,
        tipo: localStorage.getItem('tipo') ? JSON.parse(localStorage.getItem('tipo')) : null,
        id: localStorage.getItem('id') ? JSON.parse(localStorage.getItem('id')) : null,
    },

    mutations: {
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },

        setCorreo(state, correo) {
            state.correo = correo;
            localStorage.setItem('correo', JSON.stringify(correo));
        },

        setTipo(state, tipo) {
            state.tipo = tipo;
            localStorage.setItem('tipo', JSON.stringify(tipo));
        },

        setRut(state, rut) {
            state.rut = rut;
            localStorage.setItem('rut', JSON.stringify(rut));
        },

        setId(state, id) {
            state.id = id;
            localStorage.setItem('id', JSON.stringify(id));
        },

        clearState(state) {
            state.user = null;
            state.correo = null;
            state.tipo = null;
            state.rut = null;
            state.id = null;
            localStorage.removeItem('user');
            localStorage.removeItem('correo');
            localStorage.removeItem('tipo');
            localStorage.removeItem('rut');
            localStorage.removeItem('id');
        }
    },

    actions: {
        login({ commit }, { nombre, correo, tipo, rut, id }) {
            commit('setUser', nombre);
            commit('setCorreo', correo);
            commit('setTipo', tipo);
            commit('setRut', rut);
            commit('setId', id);  // Asegúrate de que el commit sea correcto aquí
        },
        logout({ commit }) {
            commit('clearState');
        }
    },

    getters: {
        user: state => state.user,
        correo: state => state.correo,
        tipo: state => state.tipo,
        rut: state => state.rut,
        id: state => state.id
    }
});

export default store;
