<template>
    <div v-if="user" style="background:#e7e7e7; min-height: 100vh;">
        <nav class="navbar navbar-expand-lg navbar-light custom-navbar-color">
            <div class="container">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/inicio">
                            <b-icon icon="house" aria-hidden="true"></b-icon> Inicio
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/registrar">
                            <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Registrar
                        </a>
                    </li>
                </ul>
                <div class="d-flex">
                    <span class="navbar-text text-white me-3">Usuario: {{ user.email }}</span>
                    <button class="btn btn-primary ml-auto" @click="logout">
                        <b-icon icon="power" aria-hidden="true"></b-icon> Cerrar sesión
                    </button>
                </div>
            </div>
        </nav>
        <div class="container mt-3">
            <div class="card">
                <div class="card-header bg-primary text-white">
                    <h2 class="text-center"><strong>Datos translados de mercaderia</strong></h2>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="filtroTipo" class="form-label">Filtrar por:</label>
                        <select id="filtroTipo" v-model="filtroTipo" class="form-select form-select-sm"
                            @change="limpiarFiltros">
                            <option value="">Seleccionar filtro</option>
                            <option value="rut">Rut</option>
                            <option value="fecha">Rango de fechas</option>
                            <option value="rut-fecha">Rut y rango de fechas</option>
                        </select>
                    </div>
                    <div v-if="filtroTipo === 'rut' || filtroTipo === 'rut-fecha'" class="mb-3">
                        <label for="filtroRut" class="form-label">Rut:</label>
                        <input type="text" id="filtroRut" v-model="filtroRut" class="form-control form-control-sm">
                    </div>
                    <div v-if="filtroTipo === 'fecha' || filtroTipo === 'rut-fecha'" class="mb-3">
                        <div class="row">
                            <div class="col-sm-2 pe-2">
                                <label for="fechaInicio" class="form-label">Fecha Inicio:</label>
                                <input type="date" id="fechaInicio" v-model="fechaInicio"
                                    class="form-control form-control-sm">
                            </div>
                            <div class="col-sm-2 ps-2">
                                <label for="fechaFin" class="form-label">Fecha Fin:</label>
                                <input type="date" id="fechaFin" v-model="fechaFin"
                                    class="form-control form-control-sm">
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-primary text-white">
                                <tr>
                                    <th scope="col">Traslado</th>
                                    <th scope="col">Folio Prov</th>
                                    <th scope="col">Proveedor</th>
                                    <th scope="col">Rut</th>
                                    <th scope="col">orden</th>
                                    <th scope="col">Patente</th>
                                    <th scope="col">Chofer</th>
                                    <th scope="col">Fecha ingreso CC</th>
                                    <th scope="col">Observación</th>
                                    <th scope="col">PDF Ord. compra</th>
                                    <th scope="col">Ingresar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dato, index) in datosFiltrados" :key="index">
                                    <td>{{ dato.folio }}</td>
                                    <td>{{ dato.Nombre }}</td>
                                    <td>{{ dato.rut }}</td>
                                    <td>{{ dato.FechaProceso }}</td>
                                    <td>
                                        <button class="btn btn-primary btn-sm"
                                            @click="descargarPDF(dato.folio)">Descargar PDF</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="login-container">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title atencion text-white">¡Atención!</h5>
                <p class="card-text">Para acceder a esta página, debes iniciar sesión.</p>
                <button @click="logout" class="btn btn-primary btn-block">Iniciar Sesión</button>
            </div>
        </div>
    </div>
</template>

<script>

import Swal from 'sweetalert2';

export default {
    data() {
        return {
            user: null,
            datos: [],
            datosCargados: false,
            filtroTipo: '',
            filtroRut: '',
            fechaInicio: '',
            fechaFin: ''
        }
    },

    created() {
        this.obtenerDatos();
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user = user;
            } else {
                this.user = null;
            }
        })
    },

    computed: {
        datosFiltrados() {
            let datosFiltrados = this.datos;

            if (this.filtroTipo === 'rut' && this.filtroRut) {
                datosFiltrados = datosFiltrados.filter(dato => dato.rut.includes(this.filtroRut));
            } else if (this.filtroTipo === 'fecha' && this.fechaInicio && this.fechaFin) {
                const fechaInicio = this.convertirFecha(this.fechaInicio);
                const fechaFin = this.convertirFecha(this.fechaFin);
                datosFiltrados = datosFiltrados.filter(dato => {
                    const fechaProceso = this.convertirFecha(dato.FechaProceso, true);
                    return fechaProceso >= fechaInicio && fechaProceso <= fechaFin;
                });
            } else if (this.filtroTipo === 'rut-fecha' && this.filtroRut && this.fechaInicio && this.fechaFin) {
                const fechaInicio = this.convertirFecha(this.fechaInicio);
                const fechaFin = this.convertirFecha(this.fechaFin);
                datosFiltrados = datosFiltrados.filter(dato => {
                    const fechaProceso = this.convertirFecha(dato.FechaProceso, true);
                    return dato.rut.includes(this.filtroRut) && fechaProceso >= fechaInicio && fechaProceso <= fechaFin;
                });
            }
            return datosFiltrados.sort((a, b) => b.folio - a.folio);
        }
    },

    methods: {
        async logout() {
            try {
                await auth.signOut();
                this.$router.push("/");
            } catch (error) {
                console.error("Error al cerrar sesión", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error al cerrar sesión",
                    text: "Ocurrió un error al intentar cerrar sesión.",
                });
            }
        },

        async obtenerDatos() {
            try {
                const querySnapshot = await db.collection('').get();
                this.datos = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        Nombre: data.nombre,
                        FechaProceso: data.fechaProceso,
                        rut: data.rut,
                        folio: data.folio,
                    };
                });

                this.datosCargados = true;
            } catch (error) {
                console.error('Error al obtener datos', error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al obtener datos',
                    text: 'Ocurrió un error al intentar obtener los datos.',
                });
            }
        },

        descargarPDF(folio) {
            const ruta = this.$router.resolve({ name: 'descargar', params: { folio: folio } });
            window.open(ruta.href, '_blank');
        },

        limpiarFiltros() {
            this.filtroRut = '';
            this.fechaInicio = '';
            this.fechaFin = '';
        },

        convertirFecha(fecha, esFechaProceso = false) {
            if (esFechaProceso) {
                // Convertir de formato DD-MM-YYYY a YYYY-MM-DD para la comparación
                const partes = fecha.split('-');
                return new Date(partes[2], partes[1] - 1, partes[0]);
            } else {
                // La fecha de los inputs de tipo date ya está en formato YYYY-MM-DD
                return new Date(fecha);
            }
        }
    },
}
</script>

<style>
.navbar {
    background: #013b8c;
}

.navbar-nav .nav-item .nav-link {
    color: white;
}

.navbar-nav .nav-item .nav-link:hover {
    color: #ffc107;
}

.navbar-text {
    color: white;
}

.btn-primary {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 16px;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.navbar-nav .nav-item {
    margin-right: 10px;
}

.cliente-column {
    width: 30%;
}

/* Estilos para ajustar el tamaño de los campos de entrada y el select */
.form-select-sm,
.form-control-sm {
    max-width: 200px;
}

.table-responsive {
    margin-top: 20px;
}
</style>
