<template>
    <div class="fondo-degradado">
        <div class="login-container">
            <div class="login-info-container">
                <div class="image-container1">
                    <img src="/assets/logo.png" alt="">
                </div>
                <form class="inputs-container" @submit.prevent="login">
                    <input id="rut" class="input" type="text" v-model="rut" @input="formatRut" required
                        placeholder="Rut">
                    <input id="pass" class="input" type="password" v-model="password" required placeholder="Contraseña">
                    <button class="btn btn-primary" type="submit">Ingresar</button>
                </form>
            </div>
            <div class="image-container">
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'Login',
    data() {
        return {
            rut: "",
            password: "",
        };
    },
    methods: {
        formatRut() {
            let rutLimpio = this.rut.replace(/[^0-9Kk]/g, '');
            if (rutLimpio.length > 1) {
                if (rutLimpio.slice(-1).toUpperCase() === 'K') {
                    rutLimpio = rutLimpio.slice(0, -1) + 'K';
                }
                const cuerpo = rutLimpio.slice(0, -1);
                const digitoVerificador = rutLimpio.slice(-1);
                this.rut = `${cuerpo}-${digitoVerificador}`;
            } else {
                this.rut = rutLimpio;
            }
        },
        async login() {
            try {
                const response = await axios.post('https://apisantiago.cmimbral.cl/login', {
                    rut: this.rut,
                    password: this.password,
                });

                const { nombre, correo, tipo, rut, id } = response.data;

                // Guardar los datos en el store
                this.$store.dispatch('login', { nombre, correo, tipo, rut, id });

                // Redirigir o hacer otra cosa luego de un login exitoso
                this.$router.push('/buscarRecep');
            } catch (error) {
                console.error("Error en el inicio de sesión", error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Credenciales inválidas o error en el servidor.",
                });
            }
        },
    },
}
</script>

<style>
.fondo-degradado {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #003A8C, white);
    display: flex;
    align-items: center;
    justify-content: center;
}

:root {
    --login-bg: #003A8C;
    --title-bg: #9089cc;
    --button-bg: white;
    --light-pink: white;
    --image-bg: rgb(255, 255, 255);
}

.login-container {
    height: 43.75em;
    width: 60em;
    margin: 6em auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.login-info-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    background-color: var(--login-bg);
}

.image-container {
    background-image: url(assets/Login.png);
    background-position: center;
    background-size: cover;
    padding: 2rem;
    width: 50%;
    background-color: var(--image-bg);
    box-sizing: border-box;
}

.image-container1 {
    background-image: url(assets/Logo.png);
    background-position: center;
    background-size: cover;
    padding: 2rem;
    width: 70%;
    background-color: var(--image-bg);
    box-sizing: border-box;
}

.inputs-container {
    margin-top: 55px;
    height: 55%;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.input,
.btns {
    width: 90%;
    height: 3.125rem;
    font-size: 1em;
}

.input {
    padding-left: 20px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
    box-sizing: border-box;
}

.input:hover {
    border: 2px solid var(--button-bg);
}

.btns {
    width: 80%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: black;
    border: none;
    border-radius: 5px;
    background-color: var(--button-bg);
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .login-container {
        width: 70%;
        margin-top: 3rem;
    }

    .login-info-container {
        width: 100%;
        border-radius: 5px;
    }

    .image-container {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .login-container {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .login-container {
        height: 90%;
    }
}
</style>
